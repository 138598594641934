* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  height: 100vh;
}

body {
  margin: 0;
  background: #FFF6F0;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  font-weight: 300;
  height: fit-content;
  font-family: "halyard-display", sans-serif;
  scrollbar-width: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ReactCrop__child-wrapper {
  height: 100%;
}

#my-reform {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  z-index: -1;
  scrollbar-width: none;
}

a { 
  text-decoration: none;
}